import store from "@/store";
import layout from "@/layouts/PanelOperator.vue";

import { routes as authRoutes } from "./auth/Routes";

const groupName =
  store.state
    .operatorRoutesGroupName; /* نام گروه کابری برای مدیریت داشبرد یا پنل مورد نظر */
const pathRoot =
  store.state
    .operatorRoutesPathRoot; /* مسیری ریشه‌ای که برای گروه کاربری در نظر گرفته‌ایم */
const parentName =
  store.state
    .operatorRoutesParentName; /* نام اصلی برای زیر مسیر‌های مدیریت داشبرد یا پنل مورد نظر */

// ------- Routes -------- -------- -------- -------- -------- -------- -------- //
export const routes = [
  {
    name: parentName,
    path: pathRoot,
    component: layout,
    meta: {
      requiresAuth: false,
      group: groupName,
    },
    children: [
      //...monitoringRoutes(parentName),

      {
        path: "",
        redirect: {
          name: parentName + "-" + "Home",
        },
      },

      {
        path: "home",
        name: parentName + "-" + "Home",
        component: () => import("./Home.vue"),
      },

      {
        path: "profile",
        name: parentName + "-" + "Profile",
        component: () => import("./Profile.vue"),
      },
    ],
  },

  // ####### Auth ####### ####### ####### ####### ####### ####### ####### ####### //
  /**
   * مهم:
   * چون لاگین و رجیستر از لایه پیشفرض گروه مدیریت پیروی نمی‌کنند باید خارج از فضای چیلدرن نوشته شود
   */

  ...authRoutes(parentName, pathRoot, groupName),
  // ####### END Auth ####### ####### ####### ####### ####### ####### ####### ####### //
];

// ------- END Routes -------- -------- -------- -------- -------- -------- -------- //

export const middleware = (record, next, checkAuth) => {
  // ------- Check Group ------- ------- ------- ------- ------- ------- ------- //
  if (record.meta.group === groupName) {
    // ------- Check Auth ------- ------- ------- ------- ------- ------- ------- //
    if (record.meta.requiresAuth) {
      //const redirectPath = window.location.pathname; // برگشت به آدرس این صفحه
      if (checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
          query: { redirect: window.location.pathname },
        });
      }
    }
    // ####### END Check Auth ####### ####### ####### ####### ####### ####### ####### //

    // ------- Check Auth Reverse ------- ------- ------- ------- ------- ------- ------- //
    else if (record.meta.requiresAuthReverse) {
      if (!checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
        });
      }
    }
    // ####### END Check Auth  Reverse ####### ####### ####### ####### ####### ####### #######  //
  }
  // ####### END Check Group ####### ####### ####### ####### ####### ####### ####### //
};

<template>
  <PrPreLoaderFull v-if="showPreLoad" />

  <aside
    v-if="!showPreLoad"
    class="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white"
    :style="[showSidebar ? { margin: 'unset !important', 'z-index': 1 } : {}]"
  >
    <div class="navbar-vertical-container">
      <div class="navbar-vertical-footer-offset">
        <!-- Logo -->

        <a class="navbar-brand" aria-label="Front">
          <img
            class="navbar-brand-logo"
            style="max-width: 5rem; height: 5rem"
            src="/assets/icons/icon-144x144.png"
            alt="Logo"
          />
        </a>

        <!-- End Logo -->

        <!-- Navbar Vertical Toggle -->
        <button
          type="button"
          class="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler"
        >
          <i
            class="bi-arrow-bar-left navbar-toggler-short-align"
            data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Collapse"
          ></i>
          <i
            class="bi-arrow-bar-right navbar-toggler-full-align"
            data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Expand"
          ></i>
        </button>

        <!-- End Navbar Vertical Toggle -->

        <!-- Content -->
        <div class="navbar-vertical-content">
          <div
            id="navbarVerticalMenu"
            class="nav nav-pills nav-vertical card-navbar-nav"
          >
            <div id="navbarVerticalMenuPagesMenu">
              <!-- +++++++ Navbar Item +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ -->
              <div
                class="nav-item"
                v-for="(item, index) in sidebarListItem"
                :key="index + '-sidebarListItems'"
              >
                <!-- +++++++ SPAN +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ -->
                <span
                  v-if="item.type === 'span'"
                  class="dropdown-header mt-4"
                  style="letter-spacing: unset"
                  >{{ $t(item.title) }}</span
                >
                <!-- +++++++ END SPAN +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ -->

                <!-- +++++++  CHANGE URL BY VUE ROUTER NAME +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ -->
                <router-link
                  v-if="item.type !== 'span' && item.url.type === 'name'"
                  class="nav-link sidebar-item-active"
                  :to="{ name: item.url.url }"
                >
                  <i class="nav-icon" :class="item.icon"></i>
                  <span class="nav-link-title">{{ $t(item.title) }}</span>
                </router-link>
                <!-- ------- END ------- ------- ------- ------- ------- ------- ------- -->

                <!-- +++++++ CHANGE URL BY LINK +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ -->
                <a
                  v-if="item.type !== 'span' && item.url.type !== 'name'"
                  class="nav-link"
                  :href="{ name: item.url.url }"
                  target="_blank"
                >
                  <i class="nav-icon" :class="item.icon"></i>
                  <span class="nav-link-title">{{ $t(item.title) }}</span>
                </a>
                <!-- -------  END ------- ------- ------- ------- ------- ------- ------- -->
              </div>
              <!-- -------  END Navbar Item ------- ------- ------- ------- ------- ------- ------- -->

              <!-- Collapse -->
            </div>
          </div>
          <!-- End Collapse -->

          <!-- Collapse -->
        </div>
        <!-- End Collapse -->
      </div>
    </div>
    <!-- End Content -->

    <!-- Footer -->
    <div class="navbar-vertical-footer">
      <ul class="navbar-vertical-footer-list">
        <li class="navbar-vertical-footer-list-item">
          <!-- Style Switcher -->
          <div class="dropdown dropup">
            <button
              type="button"
              class="btn btn-ghost-secondary btn-icon rounded-circle"
              id="selectThemeDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-dropdown-animation
            >
              <i class="bi bi-brightness-high" v-if="themeName === 'light'"></i>
              <i class="bi bi-moon" v-if="themeName === 'dark'"></i>
            </button>

            <div
              class="dropdown_botton_my dropdown-menu navbar-dropdown-menu navbar-dropdown-menu-borderless"
              aria-labelledby="selectThemeDropdown"
            >
              <a
                @click="setLight()"
                class="dropdown-item"
                :class="{ active: themeName === 'light' }"
                href="#"
                data-icon="bi-brightness-high"
                data-value="default"
              >
                <i class="bi-brightness-high ms-2"></i>
                <span class="text-truncate" title="Default (light mode)">
                  {{ $t("light") }} ({{ $t("default") }})
                </span>
              </a>
              <a
                @click="setDark()"
                class="dropdown-item"
                :class="{ active: themeName === 'dark' }"
                href="#"
                data-icon="bi-moon"
              >
                <i class="bi-moon ms-2"></i>
                <span class="text-truncate" title="Dark">
                  {{ $t("dark") }}</span
                >
              </a>
            </div>
          </div>

          <!-- End Style Switcher -->
        </li>

        <li class="navbar-vertical-footer-list-item">
          <!-- Language -->
          <div class="dropdown dropup">
            <button
              type="button"
              class="btn btn-ghost-secondary btn-icon rounded-circle"
              id="selectLanguageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-dropdown-animation
            >
              <i class="bi bi-globe"></i>
            </button>

            <div
              class="dropdown_botton_my dropdown-menu navbar-dropdown-menu-borderless"
              aria-labelledby="selectLanguageDropdown"
            >
              <span class="dropdown-header" style="text-align: center">
                {{ $t("select_language") }}
              </span>

              <a class="dropdown-item" href="#" @click="setLangFa()">
                <span class="text-truncate mx-1" title="Persian"
                  >پارسی (Persian)</span
                >
              </a>

              <a class="dropdown-item" href="#" @click="setLangEn()">
                <span class="text-truncate mx-1" title="English">English</span>
              </a>
            </div>
          </div>

          <!-- End Language -->
        </li>
      </ul>
    </div>
    <!-- End Footer -->
  </aside>
</template>

<script>
import axios from "axios";

import { setThemeDark, setThemeLight } from "@/utils/theme";

import setLanguage from "@/utils/language";

export default {
  props: ["showSidebar", "sidebarListItem"],
  data() {
    return {
      showPreLoad: false,
      time: [1000, 2000],
      themeName: this.$store.state.dashboardTheme,
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    getData() {
      const data = JSON.stringify({});
      const url = `/v1/users/details`;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.post(url, data, config).then((response) => {
        const serverResponse = response.data;
        console.log(serverResponse);
      });
    },
    setDark() {
      this.showPreLoad = true;
      this.sleep(this.time[0]).then(() => {
        setThemeDark();
        this.themeName = this.$store.state.dashboardTheme;

        this.sleep(this.time[1]).then(() => {
          this.showPreLoad = false;
        });
      });
    },

    setLight() {
      this.showPreLoad = true;
      this.sleep(this.time[0]).then(() => {
        setThemeLight();
        this.themeName = this.$store.state.dashboardTheme;

        this.sleep(this.time[1]).then(() => {
          this.showPreLoad = false;
        });
      });
    },

    setLangFa() {
      setLanguage("fa");
    },
    setLangEn() {
      setLanguage("en");
    },
  },

  mounted() {
    // this.getData();
  },
};
</script>

<style scoped>
.router-link-active.sidebar-item-active {
  background-color: #bdc5d133;
  font-weight: bold;
}
</style>
